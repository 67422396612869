import bigcommerceSparkDefaults from '../bigcommerce-spark/config.js';

const { ignoreFields } = bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetPanel');

bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'CategoryFacetPanel').type = 'SearchPage';

export default {
  ...bigcommerceSparkDefaults,
  includes: ['bigcommerce-spark'],
  SearchRequestDefaults: {
    ...bigcommerceSparkDefaults.SearchRequestDefaults,
    pageSize: 96,
  },
  Widgets: [
    ...bigcommerceSparkDefaults.Widgets.filter(
      (w) =>
        ![
          'FacetPanel',
          'SearchVehicleWidget',
          'HomeVehicleWidget',
          'FacetCategoryTiles',
          'FacetBrandTiles',
          'VerifyFitment',
          'FacetDialog',
        ].includes(w.name),
    ),
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'VehicleSpecs',
      type: 'VehicleWidget',
      fields: bigcommerceSparkDefaults.fitmentSearch.extraFields,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        firstChildOf: '.body',
        class: 'container cm_vehicle-widget__home_container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      visibleIf: () => window.innerWidth > 1024,
      fields: bigcommerceSparkDefaults.fitmentSearch.baseFields,
      columnBreakpoint: 800,
    },
    {
      name: 'HeaderVehicleWidget_mobile',
      type: 'VehicleWidget',
      location: { firstChildOf: '.body' },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      visibleIf: () => window.innerWidth <= 1024,
      fields: bigcommerceSparkDefaults.fitmentSearch.baseFields,
      columnBreakpoint: 1024,
    },
    {
      name: 'VerifyFitment',
      location: { insertBefore: 'body.page-type-product .productView-options' },
      initCollapsed: true,
      template: 'fitmentSearch/collapsibleVerifyFitment',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: 'body.page-type-brands .page',
      template: 'facetTiles/alphabeticalContainer',
      isVehicleWidgetDisabled: true,
      view: 'columns',
    },
    {
      name: 'FitmentTableMobile',
      type: 'FitmentTable',
      location: '#mobileTabFitment-content',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      ignoreFields,
    },
  ],
};
